import axios from '@/common/axios'
import qs from 'qs'

export function addSample (data) {
  return axios({
    method: 'post',
    url: '/storage/sample/add',
    data: qs.stringify(data)
  })
}

export function deleteSample (id) {
  return axios({
    method: 'delete',
    url: '/storage/sample/delete/' + id
  })
}

export function updateSample (data) {
  return axios({
    method: 'put',
    url: '/storage/sample/update',
    data: qs.stringify(data)
  })
}

export function selectSampleInfo (id) {
  return axios({
    method: 'get',
    url: '/storage/sample/info/' + id
  })
}

export function selectSampleList (query) {
  return axios({
    method: 'get',
    url: '/storage/sample/list',
    params: query
  })
}
