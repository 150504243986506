<template>
  <div id="sample">
    <el-dialog
      :title="sampleFormTitle"
      width="680px"
      :visible.sync="sampleDialogVisible"
      :close-on-click-modal="false"
      @close="sampleDialogClose"
    >
      <el-form
        ref="sampleFormRef"
        :model="sampleForm"
        :rules="sampleFormRules"
        label-position="right"
        label-width="120px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="产品名称" prop="productName">
              <el-input v-model="sampleForm.productName" placeholder="请输入产品名称" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="留样批号" prop="batchNum">
              <el-input v-model="sampleForm.batchNum" placeholder="请输入留样批号" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="留样人" prop="sampleHolder">
              <el-input v-model="sampleForm.sampleHolder" placeholder="请输入留样人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="留样数量(人份)" prop="sampleQuantity">
              <el-input v-model="sampleForm.sampleQuantity" placeholder="请输入留样数量(人份)" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="留样规格" prop="sampleSpec">
              <el-input v-model="sampleForm.sampleSpec" placeholder="请输入留样规格" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="留样日期" prop="sampleDate">
              <el-date-picker v-model="sampleForm.sampleDate" placeholder="请选择留样日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="存放位置" prop="storageLocation">
              <el-input v-model="sampleForm.storageLocation" placeholder="请输入存放位置" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="领用数量(人份)" prop="collectionQuantity">
              <el-input v-model="sampleForm.collectionQuantity" placeholder="请输入领用数量(人份)" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="领用日期" prop="collectionDate">
              <el-date-picker v-model="sampleForm.collectionDate" placeholder="请选择领用日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="领出人" prop="collector">
              <el-input v-model="sampleForm.collector" placeholder="请输入领出人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="审批人" prop="approver">
              <el-input v-model="sampleForm.approver" placeholder="请输入审批人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="用途" prop="purpose">
              <el-input v-model="sampleForm.purpose" placeholder="请输入用途" clearable />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="sampleDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="sampleFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="产品名称">
        <el-input v-model="searchForm.productName" placeholder="请输入产品名称" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="samplePage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column prop="productName" label="产品名称" />
      <el-table-column prop="batchNum" label="留样批号" />
      <el-table-column prop="sampleHolder" label="留样人" />
      <el-table-column prop="sampleQuantity" label="留样数量(人份)" />
      <el-table-column prop="sampleSpec" label="留样规格" />
      <el-table-column label="留样日期">
        <template slot-scope="scope">
          <span v-if="scope.row.sampleDate">{{ scope.row.sampleDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="storageLocation" label="存放位置" />
      <el-table-column prop="collectionQuantity" label="领用数量(人份)" />
      <el-table-column label="领用日期">
        <template slot-scope="scope">
          <span v-if="scope.row.collectionDate">{{ scope.row.collectionDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="collector" label="领出人" />
      <el-table-column prop="approver" label="审批人" />
      <el-table-column prop="purpose" label="用途" />
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="samplePage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import { addSample, deleteSample, updateSample, selectSampleInfo, selectSampleList } from '@/api/storage/sample'

export default {
  data () {
    return {
      sampleDialogVisible: false,
      sampleFormTitle: '',
      sampleForm: {
        id: '',
        productName: '',
        batchNum: '',
        sampleHolder: '',
        sampleQuantity: '',
        sampleSpec: '',
        sampleDate: '',
        storageLocation: '',
        collectionQuantity: '',
        collectionDate: '',
        collector: '',
        approver: '',
        purpose: ''
      },
      sampleFormRules: {
        productName: [{ required: true, message: '产品名称不能为空', trigger: ['blur', 'change']}]
      },
      samplePage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        productName: ''
      }
    }
  },
  created () {
    selectSampleList(this.searchForm).then(res => {
      this.samplePage = res
    })
  },
  methods: {
    sampleDialogClose () {
      this.$refs.sampleFormRef.resetFields()
    },
    sampleFormSubmit () {
      if (this.sampleFormTitle === '产品留样台账及领用详情') {
        this.sampleDialogVisible = false
        return
      }
      this.$refs.sampleFormRef.validate(async valid => {
        if (valid) {
          if (this.sampleFormTitle === '新增产品留样台账及领用') {
            await addSample(this.sampleForm)
          } else if (this.sampleFormTitle === '修改产品留样台账及领用') {
            await updateSample(this.sampleForm)
          }
          this.samplePage = await selectSampleList(this.searchForm)
          this.sampleDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.sampleFormTitle = '新增产品留样台账及领用'
      this.sampleDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteSample(row.id)
        if (this.samplePage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.samplePage = await selectSampleList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.sampleFormTitle = '修改产品留样台账及领用'
      this.sampleDialogVisible = true
      this.selectSampleInfoById(row.id)
    },
    handleInfo (index, row) {
      this.sampleFormTitle = '产品留样台账及领用详情'
      this.sampleDialogVisible = true
      this.selectSampleInfoById(row.id)
    },
    selectSampleInfoById (id) {
      selectSampleInfo(id).then(res => {
        this.sampleForm.id = res.id
        this.sampleForm.productName = res.productName
        this.sampleForm.batchNum = res.batchNum
        this.sampleForm.sampleHolder = res.sampleHolder
        this.sampleForm.sampleQuantity = res.sampleQuantity
        this.sampleForm.sampleSpec = res.sampleSpec
        this.sampleForm.sampleDate = res.sampleDate
        this.sampleForm.storageLocation = res.storageLocation
        this.sampleForm.collectionQuantity = res.collectionQuantity
        this.sampleForm.collectionDate = res.collectionDate
        this.sampleForm.collector = res.collector
        this.sampleForm.approver = res.approver
        this.sampleForm.purpose = res.purpose
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectSampleList(this.searchForm).then(res => {
        this.samplePage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectSampleList(this.searchForm).then(res => {
        this.samplePage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectSampleList(this.searchForm).then(res => {
        this.samplePage = res
      })
    }
  }
}
</script>

<style>
</style>
